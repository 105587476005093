<template>
  <section class="about">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
        </v-img>
    </v-flex>
    <v-container  grid-list-md text-center class="ctk-div-m">
      <v-layout align-center fill-height justify-center mb-4 mt-md-12 mb-md-12>
          <v-card flat class="ctk-submenu">
              <v-tabs grow light centered v-model="currentTab">
                <v-tab v-for="tab of tabs" :key="tab.key">
                  <a :href="'/'+tab.key">{{tab.name}}</a>
                </v-tab>
              </v-tabs>
          </v-card>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm12 md8 lg8 text-left pl-md-12>
          <span v-html="about.culture">{{about.culture}}</span>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 pl-md-12>
          <v-img
            :v-if="about.culture_img"
            :src="about.culture_img"
            max-height="350"
            contain
          >
            </v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    tabs: [ { key: 'about', name: '公司简介'}, { key: 'culture', name: '理念和宗旨' } ],
    currentTab: 1,
    about: {
      'culture': null,
      'culture_img': null
    }
  }),
  created () {
    document.querySelector('#menu_about').classList.add('v-btn--active')
    this.getAbout()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
